import React, { useEffect, useState } from "react";
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import AppFooter from "../../../../@crema/core/AppLayout/components/AppFooter";
import clsx from "clsx";
import { FooterType, LayoutType } from "../../../../shared/constants/AppEnums";
import { useLayoutContext } from "../../../../@crema/utility/AppContextProvider/LayoutContextProvider";
import AppContentView from "../AppContentView";
import {
  StyledAppLayoutHeaderFixedMain,
  StyledAppLayoutHorFixed,
  StyledMainScrollbar,
} from "./index.styled";

const EzHorHeaderFixed = () => {
  const [isVisible, setVisible] = useState(false);
  const { footer, footerType, layoutType } = useLayoutContext();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (layoutType === LayoutType.FRAMED) {
      document.body.classList.add("framedHorHeaderFixedLayout");
    } else {
      document.body.classList.remove("framedHorHeaderFixedLayout");
    }
  }, [layoutType]);

  return (
    <StyledAppLayoutHorFixed
      className={clsx({
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}
    >
      <AppSidebar visible={isVisible} onClose={onClose} />
      <AppHeader showDrawer={showDrawer} />
      <StyledAppLayoutHeaderFixedMain>
        <StyledMainScrollbar>
          <AppContentView />
          <AppFooter />
        </StyledMainScrollbar>
      </StyledAppLayoutHeaderFixedMain>
      {/*<AppThemeSetting />*/}
    </StyledAppLayoutHorFixed>
  );
};

export default EzHorHeaderFixed;
