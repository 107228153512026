import React from "react";
import { AppSuspense } from "../../index";
import {
  adminAuthorizedStructure,
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from "../../../ezapp/modules/index";
import AppErrorBoundary from "../AppErrorBoundary";
import generateRoutes from "../../utility/RouteGenerator";
import { useAuthUser } from "../../../ezapp/utils/AuthHooks";
import { StyledMainContentView } from "./index.styled";

const AppContentView = () => {
  const {user, isAuthenticated} = useAuthUser();
  return (
    <StyledMainContentView>
      <AppSuspense>
        <AppErrorBoundary>
          {generateRoutes({
            isAuthenticated: isAuthenticated,
            userRole: user?.role,
            unAuthorizedStructure,
            authorizedStructure,
            adminAuthorizedStructure,
            anonymousStructure,
          })}
        </AppErrorBoundary>
      </AppSuspense>
    </StyledMainContentView>
  );
};

export default AppContentView;
