import { Link } from "react-router-dom";
import React from "react";
import { useIntl } from "react-intl";
import { adminRoutesConfig, userRoutesConfig } from "../modules/routeConfig";
import { useAuthUser } from "./AuthHooks";
import { authRole } from "../../shared/constants/AppEnums";

const renderMenuItemChildren = (item) => {
  const { icon, messageId, path } = item;
  const { messages } = useIntl();

  if (path && path.includes("/"))
    return {
      key: item.id,
      icon:
        icon &&
        (React.isValidElement(icon) ? (
          <span className="ant-menu-item-icon">{icon}</span>
        ) : (
          <icon className="ant-menu-item-icon" />
        )),
      label: (
        <Link to={path}>
          <span data-testid={messageId.toLowerCase + "-nav"}>
            {messages[messageId]}
          </span>
        </Link>
      ),
    };
  else {
    return {
      key: item.id,
      icon:
        icon &&
        (React.isValidElement(icon) ? (
          <span className="ant-menu-item-icon">{icon}</span>
        ) : (
          <icon className="ant-menu-item-icon" />
        )),
      label: (
        <span data-testid={messageId.toLowerCase + "-nav"}>
          {messages[messageId]}
        </span>
      ),
    };
  }
};

const renderMenuItem = (item) => {
  return item.type === "collapse"
    ? {
        key: item.id,
        ...renderMenuItemChildren(item),
        children: item.children.map((item) => renderMenuItem(item)),
        type: "collapse",
      }
    : {
        key: item.id,
        ...renderMenuItemChildren(item),
      };
};

const renderMenu = (item) => {
  return item.type === "group"
    ? {
        key: item.path ? item.path : item.id,
        ...renderMenuItemChildren(item),
        children: item.children.map((item) => renderMenuItem(item)),
        type: "group",
      }
    : {
        key: item.id,
        exact: item.exact,
        ...renderMenuItemChildren(item),
      };
};

export const getRouteMenus = () => {
  const { user } = useAuthUser();

  let routes = user?.role === authRole.admin ? adminRoutesConfig : userRoutesConfig;

  return routes.map((route) => renderMenu(route));
};
