import React from "react";
import { RoutePermittedRole } from "../../../shared/constants/AppEnums";
import { Navigate } from "react-router-dom";

const Deposit = React.lazy(() => import("./Deposit"));
const DepositUsdt = React.lazy(() => import("./Deposit/Tron/DepositUsdt"));
const DepositUsdtBinancePay = React.lazy(() =>
  import("./Deposit/Binance/DepositUsdtBinancePay")
);
const DepUsdtBinancePayResult = React.lazy(() =>
  import("./Deposit/Binance/DepUsdtBinancePayResult")
);
const DepositBankQr = React.lazy(() => import("./Deposit/Bank/DepositBankQr"));
const UserProfile = React.lazy(() => import("./UserProfile"));
const Reward = React.lazy(() => import("./Reward"));

export const userRouteConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user",
    element: <Navigate to={"/user/profile"} />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/reward",
    element: <Reward />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/deposit",
    element: <Deposit />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/deposit/usdt-binance-pay",
    element: <DepositUsdtBinancePay />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/deposit/usdt-binance-pay/result",
    element: <DepUsdtBinancePayResult />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/deposit/usdt",
    element: <DepositUsdt />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/deposit/bankqr",
    element: <DepositBankQr />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/user/profile",
    element: <UserProfile />,
  },
];
