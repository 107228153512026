import React from "react";
import { authRouteConfig } from "./auth";
import Error403 from "./../../pages/errorPages/Error403";
import { Navigate } from "react-router-dom";
import { adminConfig } from "./admin";
import { initialUrl } from "../../shared/constants/AppConst";
import { rollRouteConfig } from "./roll";
import { errorPagesConfigs } from "../../pages/errorPages";
import { userRouteConfig } from "./user";
import { tipRouteConfig } from "./tip";
import { steamRouteConfig } from "./steam";

const Home = React.lazy(() => import("./home"));

const authorizedStructure = {
  fallbackPath: "/signin-steam",
  unAuthorizedComponent: <Error403 />,
  routes: [...userRouteConfig, ...steamRouteConfig],
};

const adminAuthorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [...adminConfig],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: [...authRouteConfig],
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "*",
      element: <Navigate to="/extra-pages/error-pages/error-404" />,
    },
    ...rollRouteConfig,
    ...tipRouteConfig,
  ]),
};

export {
  authorizedStructure,
  adminAuthorizedStructure,
  unAuthorizedStructure,
  anonymousStructure,
};
