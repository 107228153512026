import React from 'react';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import {AppContentView} from '../../../index';
import AppThemeSetting from '../../AppThemeSetting';
import clsx from 'clsx';
import {FooterType, LayoutType} from '../../../../shared/constants/AppEnums';
import AppFooter from '../components/AppFooter';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {
  StyledAppLayoutMini,
  StyledAppLayoutMiniMain,
  StyledMainMiniScrollbar,
} from './index.styled';

const MiniSidebar = () => {
  const {footer, layoutType, footerType} = useLayoutContext();

  return (
    <StyledAppLayoutMini
      className={clsx({
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
        boxedLayout: layoutType === LayoutType.BOXED,
      })}>
      <AppSidebar />
      <StyledAppLayoutMiniMain className='app-layout-mini-main'>
        <AppHeader />
        <StyledMainMiniScrollbar>
          <AppContentView />
          <AppFooter />
        </StyledMainMiniScrollbar>
      </StyledAppLayoutMiniMain>
      <AppThemeSetting />
    </StyledAppLayoutMini>
  );
};

export default MiniSidebar;
