import styled from 'styled-components';

export const StyledAppLogo = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  width: 380px;

  & img {
    height: 70px;
    margin-right: 10px;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  
  & span {
    line-height: 1em;
    font-size: 1.6em;
    font-family: 'lato', sans-serif;
    font-weight: 900;
    letter-spacing: .1em;
    color: lightyellow;
    text-align: center;
    text-shadow: 0 0 2px yellow,
    0 0 4px gold,
    0 0 6px orange,
    0 0 8px black;
  }
`;

export const StyledLogoText = styled.span`
  display: none;

  @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
    display: inline;
  }
`;
