import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  StyledAppMainSidebar,
  StyledAppSidebarScrollbar,
} from "./index.styled";
import { useSidebarContext } from "../../../../@crema/utility/AppContextProvider/SidebarContextProvider";
import AppVerticalMenu from "../../../../@crema/core/AppLayout/components/AppVerticalNav";
import UserInfo from "../../UserInfo";
import LoginSteamButton from "../../LoginSteamButton";
import { useAuthUser } from "../../../utils/AuthHooks";

const AppSidebar = ({ isCollapsed }) => {
  const { isSidebarBgImage } = useSidebarContext();
  const { isAuthenticated } = useAuthUser();

  return (
    <StyledAppMainSidebar
      className={clsx({
        "sidebar-img-background": isSidebarBgImage,
      })}
      collapsible
      breakpoint="xl"
      collapsed={isCollapsed}
    >
      {isAuthenticated ? <UserInfo hasColor/> : <LoginSteamButton/>}
      <StyledAppSidebarScrollbar scrollToTop={false}>
        <AppVerticalMenu />
      </StyledAppSidebarScrollbar>
    </StyledAppMainSidebar>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
};
