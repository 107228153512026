import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useInfoViewActionsContext } from "../../@crema/utility/AppContextProvider/InfoViewContextProvider";
import {
  getAuthToken,
  login,
  loginSteam,
  logout,
  verifySteamUrl,
} from "../services/auth";
import { getUserInfo, updateUserInfo } from "../services/user";

const EzJWTAuthContext = createContext({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const EzJWTAuthActionsContext = createContext({
  signUpUser: () => {},
  signInUser: () => {},
  signInSteam: () => {},
  verifySignInSteam: () => {},
  logoutUser: () => {},
  updateUser: () => {},
  refreshUser: () => {},
});

export const useJWTAuth = () => useContext(EzJWTAuthContext);

export const useJWTAuthActions = () => useContext(EzJWTAuthActionsContext);

const EzJWTAuthAuthProvider = ({ children }) => {
  const { fetchStart, fetchSuccess, fetchError } = useInfoViewActionsContext();
  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  useEffect(() => {
    const getAuthUser = () => {
      const token = getAuthToken();

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      getUserInfo()
        .then((userInfo) => {
          setJWTAuthData({
            user: userInfo,
            isAuthenticated: true,
            isLoading: false,
          });
        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          })
        );
    };

    getAuthUser();
  }, []);

  const signInSteam = async () => {
    fetchStart();
    loginSteam()
      .then((loginUrl) => {
        fetchSuccess();
        window.location.href = loginUrl;
      })
      .catch((error) => {
        fetchError(error.message);
      });
  };

  const verifySignInSteam = (callbackUrl, callbackFun) => {
    fetchStart();
    verifySteamUrl(callbackUrl)
      .then(async () => {
        fetchSuccess();
        const userInfo = await getUserInfo();
        setJWTAuthData({
          user: userInfo,
          isAuthenticated: true,
          isLoading: false,
        });
        if (callbackFun) {
          callbackFun();
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const signInUser = async ({ username, password }, callbackFun) => {
    fetchStart();
    login({ username, password })
      .then(async () => {
        fetchSuccess();
        const userInfo = await getUserInfo();
        setJWTAuthData({
          user: userInfo,
          isAuthenticated: true,
          isLoading: false,
        });
        if (callbackFun) {
          callbackFun();
        }
      })
      .catch(function (error) {
        console.log(error.message);
        fetchError(error.message);
      });
  };

  const signUpUser = async ({ name, email, password }) => {
    console.log(name);
    console.log(email);
    console.log(password);
    // todo later
  };

  const logoutUser = async (callbackFun) => {
    fetchStart();
    logout()
      .then(() => {
        fetchSuccess();
        setJWTAuthData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
        if (callbackFun) {
          callbackFun();
        }
      })
      .catch((error) => {
        fetchError(error.message);
      });
  };

  const updateUser = async ({ currency, displayName, email }) => {
    fetchStart();
    updateUserInfo({ currency, displayName, email })
      .then(async (updatedUser) => {
        fetchSuccess();
        setJWTAuthData({
          user: updatedUser,
          isAuthenticated: true,
          isLoading: false,
        });
      })
      .catch((error) => fetchError(error.message));
  };

  const refreshUser = async () => {
    try {
      const userInfo = await getUserInfo();
      setJWTAuthData({
        user: userInfo,
        isAuthenticated: true,
        isLoading: false,
      });
    } catch (error) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      })
    }
  };

  return (
    <EzJWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}
    >
      <EzJWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInSteam,
          verifySignInSteam,
          signInUser,
          logoutUser,
          updateUser,
          refreshUser,
        }}
      >
        {children}
      </EzJWTAuthActionsContext.Provider>
    </EzJWTAuthContext.Provider>
  );
};
export default EzJWTAuthAuthProvider;

EzJWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
