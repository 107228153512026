import React from "react";

const OAuthSteam = React.lazy(() => import("../OAuthSteam"));
const SignIn = React.lazy(() => import("./Signin/SignIn"));
const SignInSteam = React.lazy(() => import("./Signin/SignInSteam"));

export const authRouteConfig = [
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signin-steam",
    element: <SignInSteam />,
  },
  {
    path: "/auth/verify-steam",
    element: <OAuthSteam />,
  },
];
