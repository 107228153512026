import EzHorDarkLayout from "./EzHorDarkLayout_unUsed";
import EmptyDarkLayout from "./EmptyDarkLayout";
import EzVerDarkLayout from "./EzVerDarkLayout";
import { EzNavStyle } from "../../../shared/constants/AppEnums";
import EzHorHeaderFixed from "./EzHorHeaderFixed";

const LayoutMap = {
  [EzNavStyle.EZ_HOR_DARK_LAYOUT]: EzHorDarkLayout,
  [EzNavStyle.EZ_EMPTY_DARK_LAYOUT]: EmptyDarkLayout,
  [EzNavStyle.EZ_VER_DARK_LAYOUT]: EzVerDarkLayout,
  [EzNavStyle.EZ_HOR_HEADER_FIXED]: EzHorHeaderFixed,
};
export default LayoutMap;
