import React, {useCallback} from "react";
import languageData from "./data";

import PropTypes from "prop-types";
import {Dropdown, Tooltip} from "antd";
import {LayoutDirection} from "../../../shared/constants/AppEnums";
import {useLocaleActionsContext, useLocaleContext,} from "../../utility/AppContextProvider/LocaleContextProvide";
import {useLayoutActionsContext} from "../../utility/AppContextProvider/LayoutContextProvider";
import {StyledLangBtn, StyledLangIcon, StyledLangItem, StyledLangText,} from "./index.styled";
import {useIntl} from "react-intl";
import _ from "lodash";
import {IoLanguageOutline} from "react-icons/io5";

const AppLanguageSwitcher = () => {
  const {rtlLocale, locale} = useLocaleContext();
  const {updateLocale} = useLocaleActionsContext();
  const {updateDirection} = useLayoutActionsContext();
  const {messages} = useIntl();

  const changeLanguage = (language) => {
    if (rtlLocale.indexOf(language.locale) !== -1) {
      updateDirection(LayoutDirection.RTL);
    } else {
      updateDirection(LayoutDirection.LTR);
    }
    updateLocale(language);
  };

  const items = languageData.map((language, index) => {
    return {
      key: index,
      label: (
        <StyledLangItem key={index} onClick={() => changeLanguage(language)}>
          <i className={`flag flag-24 flag-${language.icon}`}/>
          <h4>{language.name}</h4>
        </StyledLangItem>
      ),
    };
  });

  const getLanguageByLocale = useCallback((locale) => {
    return _.find(languageData, {locale: locale.locale})
  }, []);

  return (
    <>
      <Dropdown
        menu={{items}}
        trigger={['click']}
        overlayStyle={{zIndex: 1052}}>
        <Tooltip title={messages["home.changeLanguage"]} placement={"left"}>
          <StyledLangBtn
            className='ant-dropdown-link langBtn'
            onClick={(e) => e.preventDefault()}>
            <StyledLangIcon>
              {(locale && getLanguageByLocale(locale))
                ? <i className={`flag flag-24 flag-${getLanguageByLocale(locale).icon}`}/>
                : <IoLanguageOutline/>}
            </StyledLangIcon>
            <StyledLangText className='lang-text'>{locale.name}</StyledLangText>
          </StyledLangBtn>
        </Tooltip>
      </Dropdown>
    </>
  );
};

export default AppLanguageSwitcher;

AppLanguageSwitcher.defaultProps = {
  iconOnly: false,
};

AppLanguageSwitcher.propTypes = {
  iconOnly: PropTypes.bool,
};
