import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import defaultConfig from "./defaultConfig";
import PropTypes from "prop-types";

const LocaleContext = createContext();
const LocaleActionsContext = createContext();

export const useLocaleContext = () => useContext(LocaleContext);

export const useLocaleActionsContext = () => useContext(LocaleActionsContext);

const LocaleContextProvider = ({children}) => {
  const [locale, updateLocale] = useState(defaultConfig.locale);

  useEffect(() => {
    const cachedLocale = localStorage.getItem("locale");
    if (cachedLocale) {
      updateLocale(JSON.parse(cachedLocale));
    }
  }, []);

  const handleUpdateLocal = useCallback((locale) => {
    updateLocale(locale);
    localStorage.setItem("locale", JSON.stringify(locale));
  },[]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        rtlLocale: defaultConfig.rtlLocale,
      }}>
      <LocaleActionsContext.Provider
        value={{
          updateLocale: handleUpdateLocal,
        }}>
        {children}
      </LocaleActionsContext.Provider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
