import React from "react";
import PropTypes from "prop-types";
import { useSidebarContext } from "../../../@crema/utility/AppContextProvider/SidebarContextProvider";
import { StyledAppLogo, StyledLogoText } from "./index.styled";
import {useNavigate} from "react-router-dom";

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  const navigate = useNavigate();

  return (
    <StyledAppLogo onClick={() => {navigate("/")}}>
      {hasSidebarColor && sidebarColorSet.mode === "dark" ? (
        <img src="/ezlogo.png" alt="ezvalue-logo" />
      ) : (
        <img src="/ezlogo.png" alt="ezvalue-logo" />
      )}
      <StyledLogoText>ETOPFUN<br/>VALUE</StyledLogoText>
    </StyledAppLogo>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
