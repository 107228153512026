import React from 'react';
import {Dropdown} from 'antd';
import {FiMoreVertical} from 'react-icons/fi';
import AppLogo from '../../AppLogo';
import AppLanguageSwitcher from '../../../../@crema/core/AppLanguageSwitcher';
import {AiOutlineMenu} from 'react-icons/ai';
import PropTypes from 'prop-types';
import {useSidebarContext} from '../../../../@crema/utility/AppContextProvider/SidebarContextProvider';
import {
  StyledAppHeaderHorFixed,
  StyledAppHeaderHorFixedMain,
  StyledAppHeaderHorMainFlex,
  StyledAppHorizontalNav,
  StyledContainer,
  StyledHeaderHorFixedSecDesktop,
  StyledHeaderHorFixedSecMobile,
} from './index.styled';
import {StyledDropdownWrapper} from '../../../../@crema/core/AppLayout/index.styled';
import {useAuthUser} from "../../../utils/AuthHooks";
import LoginSteamButton from "../../LoginSteamButton";
import AppCurrencySwitcher from "../../AppCurrencySwitcher";
import UserInfo from "../../UserInfo";
import UserBalance from "../../UserInfo/UserBalance";

const items = [
  {key: 1, label: <AppLanguageSwitcher />},
  {key: 2, label: <AppCurrencySwitcher />}
];

const AppHeader = ({showDrawer}) => {
  const { isAuthenticated } = useAuthUser();
  const {sidebarColorSet} = useSidebarContext();

  return (
    <StyledAppHeaderHorFixed
      style={{
        backgroundColor: sidebarColorSet.sidebarBgColor,
        color: sidebarColorSet.sidebarTextColor,
      }}>
      <StyledAppHeaderHorFixedMain>
        <StyledContainer className='container'>
          <StyledAppHeaderHorMainFlex>
            <a className='trigger' onClick={showDrawer}>
              <AiOutlineMenu />
            </a>
            <AppLogo hasSidebarColor />
            <StyledAppHorizontalNav />
            {isAuthenticated && <UserBalance />}
            <StyledHeaderHorFixedSecDesktop>
              <AppLanguageSwitcher />
              <AppCurrencySwitcher />
              {isAuthenticated ? <UserInfo /> : <LoginSteamButton/>}
            </StyledHeaderHorFixedSecDesktop>

            <StyledHeaderHorFixedSecMobile>
              <StyledDropdownWrapper>
                <Dropdown
                  menu={{items}}
                  overlayClassName='dropdown-wrapper'
                  getPopupContainer={(triggerNode) => triggerNode}
                  trigger={['click']}>
                  <a
                    className='ant-dropdown-link-mobile'
                    onClick={(e) => e.preventDefault()}>
                    <FiMoreVertical />
                  </a>
                </Dropdown>
              </StyledDropdownWrapper>
            </StyledHeaderHorFixedSecMobile>
          </StyledAppHeaderHorMainFlex>
        </StyledContainer>
      </StyledAppHeaderHorFixedMain>
    </StyledAppHeaderHorFixed>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  showDrawer: PropTypes.func,
};
