import React from "react";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";
import {Dropdown} from "antd";
import {FaChevronDown} from "react-icons/fa";
import PropTypes from "prop-types";
import {
  StyledCrUserInfo,
  StyledCrUserInfoAvatar,
  StyledCrUserInfoContent,
  StyledCrUserInfoInner,
  StyledUserArrow,
  StyledUsername,
  StyledUsernameInfo,
} from "./index.styled";
import {useThemeContext} from "../../../@crema/utility/AppContextProvider/ThemeContextProvider";
import {useAuthMethod, useAuthUser} from "../../utils/AuthHooks";
import {useIntl} from "react-intl";
import {initialUrl} from "../../../shared/constants/AppConst";

const UserInfo = () => {
  const { themeMode } = useThemeContext();
  const { logoutUser } = useAuthMethod();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { messages } = useIntl();

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const items = [
    {
      key: 1,
      label: (
        <div onClick={() => navigate("/user/profile")}>
          {messages["user.my-profile"]}
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div onClick={() => navigate("/user/reward")}>
          {messages["user.rewards"]}
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={() =>
            logoutUser(() => navigate(initialUrl, { replace: true }))
          }
        >
          {messages["auth.logout"]}
        </div>
      ),
    },
  ];

  return (
    <>
      <StyledCrUserInfo
        className={clsx("cr-user-info", {
          light: themeMode === "light",
        })}
      >
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          overlayStyle={{
            zIndex: 1052,
            minWidth: 150,
          }}
        >
          <StyledCrUserInfoInner className="ant-dropdown-link">
            {user.photoURL ? (
              <StyledCrUserInfoAvatar src={user.photoURL} />
            ) : (
              <StyledCrUserInfoAvatar>{getUserAvatar()}</StyledCrUserInfoAvatar>
            )}
            <StyledCrUserInfoContent className="cr-user-info-content">
              <StyledUsernameInfo>
                <StyledUsername
                  className={clsx("text-truncate", {
                    light: themeMode === "dark",
                  })}
                >
                  {user.displayName ? user.displayName : user.username}
                </StyledUsername>
                <StyledUserArrow className="cr-user-arrow">
                  <FaChevronDown />
                </StyledUserArrow>
              </StyledUsernameInfo>
            </StyledCrUserInfoContent>
          </StyledCrUserInfoInner>
        </Dropdown>
      </StyledCrUserInfo>
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
