import React from 'react';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import {AppContentView} from '../../../index';
import AppThemeSetting from '../../AppThemeSetting';
import clsx from 'clsx';
import {FooterType, LayoutType} from '../../../../shared/constants/AppEnums';
import AppFooter from '../components/AppFooter';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {
  StyledAppLayoutMinibar,
  StyledAppLayoutMinibarMain,
  StyledUserMiniHeaderScrollbar,
} from './index.styled';

const UserMiniHeader = () => {
  const {footer, layoutType, footerType} = useLayoutContext();

  return (
    <StyledAppLayoutMinibar
      className={clsx({
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
        boxedLayout: layoutType === LayoutType.BOXED,
      })}>
      <AppSidebar />
      <StyledAppLayoutMinibarMain className='app-layout-userMiniHeader-main'>
        <AppHeader />
        <StyledUserMiniHeaderScrollbar>
          <AppContentView />
          <AppFooter />
        </StyledUserMiniHeaderScrollbar>
      </StyledAppLayoutMinibarMain>
      <AppThemeSetting />
    </StyledAppLayoutMinibar>
  );
};

export default UserMiniHeader;
