import axios from "axios";
import _ from "lodash";
import { getAuthToken, logout } from "../services/auth";

const apiBaseURL = "/api";

const statusCode = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

// interface IRequest extends AxiosRequestConfig {
//     isFormData?: boolean;
//     authRequired: boolean;
// }

export async function request({
  url,
  method = "GET",
  data,
  isFormData,
  authRequired = true,
  ...rest
}) {
  let token;
  let submitData = data;

  if (authRequired) {
    token = getAuthToken();
  }

  if (isFormData) {
    const formData = new FormData();
    _.forEach(submitData, (value, key) => {
      formData.append(key, value);
    });
    submitData = formData;
  }
  try {
    const response = await axios({
      baseURL: apiBaseURL,
      url,
      method,
      headers: {
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : null),
      },
      data: submitData,
      ...rest,
    });

    return response && response.data;
  } catch (error) {
    const status = error.response.status;
    if (status === statusCode.UNAUTHORIZED) {
      await logout();
    }
    let errorMessage = error?.response?.data?.message;
    if (!errorMessage) {
      errorMessage = error.message;
    }
    return Promise.reject({ status, errorMessage, ...error });
  }
}
