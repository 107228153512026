import React from 'react';
import collapseMotion from 'antd/lib/_util/motion';
import clsx from 'clsx';
import AppVerticalMenu from '../components/AppVerticalNav';
import {useSidebarContext} from '../../../utility/AppContextProvider/SidebarContextProvider';
import {StyledAppScrollBarMini, StyledUserMiniSidebar} from './index.styled';

const AppSidebar = () => {
  const {isSidebarBgImage} = useSidebarContext();

  return (
    <StyledUserMiniSidebar
      className={clsx({
        'userMiniHeader-sidebar-img-background': isSidebarBgImage,
      })}
      breakpoint='lg'
      collapsed={collapseMotion}>
      <StyledAppScrollBarMini scrollToTop={false}>
        <AppVerticalMenu />
      </StyledAppScrollBarMini>
    </StyledUserMiniSidebar>
  );
};

export default AppSidebar;
