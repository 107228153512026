import { StyledAuth, StyledMainAuthScrollbar } from "../index.styled";
import AppContentView from "../AppContentView";
import React from "react";

const EmptyDarkLayout = () => {
  return(
    <StyledAuth>
      <StyledMainAuthScrollbar>
        <AppContentView />
      </StyledMainAuthScrollbar>
    </StyledAuth>
  );
};

export default EmptyDarkLayout;