import React, { useEffect, useState } from "react";
import AppHeader from "./AppHeader";
import clsx from "clsx";
import { FooterType, LayoutType } from "../../../../shared/constants/AppEnums";
import AppSidebar from "./AppSidebar";
import {
  StyledAppLayoutHorDark,
  StyledAppLayoutHorDarkMain,
  StyledContainer,
} from "./index.styled";
import { useLayoutContext } from "../../../../@crema/utility/AppContextProvider/LayoutContextProvider";

import AppFooter from "../../../../@crema/core/AppLayout/components/AppFooter";
// import AppThemeSetting from "../../../../@crema/core/AppThemeSetting";
import AppContentView from "../AppContentView";

const EzHorDarkLayout = () => {
  const [isVisible, setVisible] = useState(false);
  const { footer, footerType, layoutType } = useLayoutContext();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (layoutType === LayoutType.FRAMED) {
      document.body.classList.add("framedHorDarkLayout");
    } else {
      document.body.classList.remove("framedHorDarkLayout");
    }
  }, [layoutType]);

  return (
    <StyledAppLayoutHorDark
      className={clsx({
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}
    >
      <AppSidebar visible={isVisible} onClose={onClose} />
      <AppHeader showDrawer={showDrawer} />
      <StyledAppLayoutHorDarkMain>
        <StyledContainer>
          <AppContentView />
          <AppFooter />
        </StyledContainer>
      </StyledAppLayoutHorDarkMain>
      {/*<AppThemeSetting />*/}
    </StyledAppLayoutHorDark>
  );
};

export default EzHorDarkLayout;
