import React from "react";
import PropTypes from "prop-types";
import TweenOne from "rc-tween-one";
import Children from "rc-tween-one/lib/plugin/ChildrenPlugin";

TweenOne.plugins.push(Children);

export default function AnimateNumber({
  value,
  formatMoney,
  floatLength,
  duration,
}) {
  return (
    <TweenOne
      animation={{
        Children: {
          value: value,
          formatMoney: formatMoney,
          floatLength: floatLength,
        },
        duration: duration,
      }}
    >
      0
    </TweenOne>
  );
}

AnimateNumber.propTypes = {
  value: PropTypes.number.isRequired,
  formatMoney: PropTypes.bool,
  floatLength: PropTypes.number,
  duration: PropTypes.number,
};

AnimateNumber.defaultProps = {
  floatLength: 2,
  duration: 1000,
};
