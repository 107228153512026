import { IoLogoAppleAr, IoWallet } from "react-icons/io5";
import { MdOutlineDashboard, MdPeopleAlt, MdSecurity } from "react-icons/md";
import { HiCash, HiGift } from "react-icons/hi";
import { BsSteam } from "react-icons/bs";
import { AiFillGift } from "react-icons/ai";
import { FaCoins, FaSteamSymbol } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";

export const userRoutesConfig = [
  {
    id: "home",
    title: "Home Page",
    messageId: "common.homePage",
    path: "/",
    type: "group",
    children: [],
  },
  {
    id: "roll",
    title: "Roll Store",
    messageId: "roll.store",
    path: "/roll",
    type: "group",
    children: [],
  },
  {
    id: "tip",
    title: "Tip",
    messageId: "tip",
    path: "/tip",
    type: "group",
    children: [],
  },
  {
    id: "steam-valuation",
    title: "Steam Valuation",
    messageId: "user.steam-valuation.title",
    path: "/steam-valuation",
    type: "group",
    children: [],
  },
];

export const adminRoutesConfig = [
  {
    id: "admin",
    title: "Admin",
    messageId: "admin",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        messageId: "admin.dashboard",
        icon: <MdOutlineDashboard />,
        path: "/admin/dashboard",
      },
    ],
  },
  {
    id: "configuration",
    title: "Configuration",
    messageId: "admin.configuration",
    type: "group",
    children: [
      {
        id: "security",
        title: "Security",
        messageId: "admin.configuration.security",
        icon: <MdSecurity />,
        path: "/admin/configuration/security",
      },
      {
        id: "third-party",
        title: "Third-party",
        messageId: "admin.configuration.third-party",
        icon: <IoLogoAppleAr />,
        path: "/admin/configuration/third-party",
      },
    ],
  },
  {
    id: "management",
    title: "Management",
    messageId: "admin.management",
    type: "group",
    children: [
      {
        id: "management.user",
        title: "User",
        messageId: "admin.management.user",
        icon: <MdPeopleAlt />,
        path: "/admin/management/user",
      },
      {
        id: "management.roll",
        title: "Roll",
        messageId: "admin.management.roll",
        icon: <HiGift />,
        path: "/admin/management/roll",
      },
      {
        id: "management.tip",
        title: "Tip",
        messageId: "admin.management.tip",
        icon: <FaCoins />,
        path: "/admin/management/tip",
      },
      {
        id: "transaction",
        title: "Transaction",
        messageId: "admin.management.transaction",
        icon: <GrTransaction />,
        path: "/admin/management/transaction",
      },
      {
        id: "transaction",
        title: "Transaction",
        messageId: "admin.management.depositBank",
        icon: <HiCash />,
        path: "/admin/management/deposit-bank",
      },
      {
        id: "wallet",
        title: "wallet",
        messageId: "admin.management.userWallet",
        icon: <IoWallet />,
        path: "/admin/management/wallet",
      },
      {
        id: "steamOffers",
        title: "Steam Offers",
        messageId: "admin.management.steamOffer",
        icon: <FaSteamSymbol />,
        path: "/admin/management/steamOffer",
      },
    ],
  },
  {
    id: "service",
    title: "Service",
    messageId: "admin.service",
    type: "group",
    children: [
      {
        id: "roll",
        title: "Roll Store",
        messageId: "roll.store",
        path: "/roll",
        icon: <AiFillGift />,
      },
      {
        id: "tip",
        title: "Tip",
        messageId: "tip",
        path: "/tip",
        icon: <FaCoins />,
      },
      {
        id: "steam-valuation",
        title: "Steam Valuation",
        messageId: "user.steam-valuation.title",
        icon: <BsSteam />,
        path: "/admin/service/steam-valuation",
      },
    ],
  },
];
