import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import UserInfo from "../../UserInfo";
import clsx from 'clsx';
import AppVerticalMenu from '../../../../@crema/core/AppLayout/components/AppVerticalNav';
import {LayoutDirection} from '../../../../shared/constants/AppEnums';
import {useSidebarContext} from '../../../../@crema/utility/AppContextProvider/SidebarContextProvider';
import {useLayoutContext} from '../../../../@crema/utility/AppContextProvider/LayoutContextProvider';
import {
  StyledAppHeaderDrawerFixed,
  StyledAppHorHeaderFixedSidebar,
  StyledAppHorScrollbar,
} from './index.styled';
import LoginSteamButton from "../../LoginSteamButton";
import { useAuthUser } from "../../../utils/AuthHooks";

const AppSidebar = ({visible, onClose}) => {
  const { isAuthenticated } = useAuthUser();
  const {isSidebarBgImage} = useSidebarContext();
  const {direction} = useLayoutContext();
  const {pathname} = useLocation();

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <StyledAppHeaderDrawerFixed
      placement={direction === LayoutDirection.LTR ? 'left' : 'right'}
      closable={false}
      onClose={onClose}
      open={visible}>
      <StyledAppHorHeaderFixedSidebar
        className={clsx({
          'hor-header-fixed-sidebar-img-background': isSidebarBgImage,
        })}
        collapsible>
        {isAuthenticated ? <UserInfo /> : <LoginSteamButton/>}
        <StyledAppHorScrollbar scrollToTop={false}>
          <AppVerticalMenu />
        </StyledAppHorScrollbar>
      </StyledAppHorHeaderFixedSidebar>
    </StyledAppHeaderDrawerFixed>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
