import React from 'react';
import collapseMotion from 'antd/lib/_util/motion';
import UserInfo from '../components/UserInfo';
import clsx from 'clsx';
import AppVerticalMenu from '../components/AppVerticalNav';
import {useSidebarContext} from '../../../utility/AppContextProvider/SidebarContextProvider';
import {StyledAppSidebarMiniScrollbar, StyledMiniSidebar} from './index.styled';

const AppSidebar = () => {
  const {isSidebarBgImage} = useSidebarContext();

  return (
    <StyledMiniSidebar
      breakpoint='lg'
      className={clsx({
        'mini-sidebar-img-background': isSidebarBgImage,
      })}
      collapsed={collapseMotion}>
      <UserInfo hasColor />
      <StyledAppSidebarMiniScrollbar scrollToTop={false}>
        <AppVerticalMenu />
      </StyledAppSidebarMiniScrollbar>
    </StyledMiniSidebar>
  );
};

export default AppSidebar;
