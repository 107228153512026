import React from "react";
import { Dropdown } from "antd";
import { FiMoreVertical } from "react-icons/fi";
import NotificationBar from "./NotificationBar";
import { AiOutlineMenu } from "react-icons/ai";
import PropTypes from "prop-types";

import {
  StyledAppHeaderHorDark,
  StyledAppMainHorDarkMainMenu,
  StyledContainer,
  StyledDropdownWrapper,
  StyledHeaderHorDarkMain,
  StyledHeaderHorDarkSecDesktop,
  StyledHeaderHorDarkSecMobile,
  StyledHeaderNavHorDark,
  StyledHorDarkMainFlex,
} from "./index.styled";
import AppLanguageSwitcher from "../../../../@crema/core/AppLanguageSwitcher";
import { useSidebarContext } from "../../../../@crema/utility/AppContextProvider/SidebarContextProvider";
import AppLogo from "../../AppLogo";
import UserInfo from "../../UserInfo";
import styled from "styled-components";
import { useAuthUser } from "../../../utils/AuthHooks";
import LoginSteamButton from "../../LoginSteamButton";
import AppCurrencySwitcher from "../../AppCurrencySwitcher";

const items = [
  { key: 1, label: <AppLanguageSwitcher /> },
  { key: 2, label: <AppCurrencySwitcher /> },
];

const StyledRightMenuContainer = styled.div`
  display: flex;
`;

const AppHeader = ({ showDrawer }) => {
  const { isAuthenticated } = useAuthUser();
  const { sidebarColorSet } = useSidebarContext();

  return (
    <StyledAppHeaderHorDark
      style={{
        backgroundColor: sidebarColorSet.sidebarBgColor,
        color: sidebarColorSet.sidebarTextColor,
      }}
    >
      <NotificationBar />

      <StyledHeaderHorDarkMain
        style={{
          backgroundColor: sidebarColorSet.sidebarBgColor,
          color: sidebarColorSet.sidebarTextColor,
        }}
      >
        <StyledContainer>
          <StyledHorDarkMainFlex>
            <a className="trigger" onClick={showDrawer}>
              <AiOutlineMenu />
            </a>
            <AppLogo hasSidebarColor />
            <StyledRightMenuContainer>
              <StyledHeaderHorDarkSecDesktop>
                <AppLanguageSwitcher />
                <AppCurrencySwitcher />
              </StyledHeaderHorDarkSecDesktop>
              {isAuthenticated ? <UserInfo /> : <LoginSteamButton/>}
              <StyledHeaderHorDarkSecMobile>
                <StyledDropdownWrapper>
                  <Dropdown
                    menu={{ items }}
                    overlayClassName="dropdown-wrapper"
                    getPopupContainer={(triggerNode) => triggerNode}
                    trigger={["click"]}
                  >
                    <a
                      className="ant-dropdown-link-mobile"
                      onClick={(e) => e.preventDefault()}
                    >
                      <FiMoreVertical />
                    </a>
                  </Dropdown>
                </StyledDropdownWrapper>
              </StyledHeaderHorDarkSecMobile>
            </StyledRightMenuContainer>
          </StyledHorDarkMainFlex>
        </StyledContainer>
      </StyledHeaderHorDarkMain>

      <StyledHeaderNavHorDark
        style={{
          backgroundColor: sidebarColorSet.sidebarBgColor,
          color: sidebarColorSet.sidebarTextColor,
        }}
      >
        <StyledContainer>
          <StyledAppMainHorDarkMainMenu />
        </StyledContainer>
      </StyledHeaderNavHorDark>
    </StyledAppHeaderHorDark>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  showDrawer: PropTypes.func,
};
