import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {Dropdown, Tooltip} from "antd";
import currencyData from "./data";
import styled from "styled-components";
import {darken, lighten} from "polished";
import {useAuthMethod, useAuthUser} from "../../utils/AuthHooks";
import {useIntl} from "react-intl";

const StyledCurrencyItem = styled.div`
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & h4 {
        margin-left: 16px;
        margin-bottom: 0;
        font-weight: ${({theme}) => theme.font.weight.medium};
        font-size: ${({theme}) => theme.font.size.base};

        @media screen and (min-width: ${({theme}) => theme.breakpoints.xl}px) {
            font-size: ${({theme}) => theme.font.size.lg};
        }
    }
`;

const StyledCurrencyBtn = styled.a`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: ${({theme}) => theme.font.size.lg};
    border-radius: 0;
    padding: 2px 12px 18px 12px;
    color: ${({theme}) => theme.palette.text.primary};
    text-transform: capitalize;

    &:hover,
    &:focus {
        color: ${({theme}) => theme.palette.text.primary};
        background-color: transparent;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
        font-weight: ${({theme}) => theme.font.weight.medium};
        text-transform: uppercase;
        margin-top: 0;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background-color: ${({theme}) =>
                theme.palette.background.default} !important;
        color: ${({theme}) => theme.palette.text.secondary} !important;
        padding: 9px;
        border-radius: ${({theme}) => theme.sizes.borderRadius.circle};
        border: 1px solid transparent;
        margin-left: 7.4px;
        margin-right: 7.4px;
        line-height: 1;

        &:hover,
        &:focus {
            color: ${({theme}) => theme.palette.text.primary};
            background-color: ${({theme}) =>
                    lighten(0.005, theme.palette.background.default)};

            border-color: ${({theme}) =>
                    darken(0.1, theme.palette.background.default)};
        }
    }
`;

const StyledCurrencyIcon = styled.span`
    position: relative;
    display: none;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
        display: block;
    }
`;

const StyledCurrencyText = styled.h4`
    font-size: ${({theme}) => theme.font.size.lg};
    font-weight: ${({theme}) => theme.font.weight.medium};
    display: inline-block;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
        display: none;
    }
`;

export default function AppCurrencySwitcher() {
  const {user, isAuthenticated} = useAuthUser();
  const {updateUser} = useAuthMethod();
  const {messages} = useIntl();
  const [initCurrencyId, setInitCurrencyId] = useState(currencyData[0].id);
  const [selectedCurrency, setSelectedCurrency] = useState(currencyData[0]);

  useEffect(() => {
    if (user && user.currency) {
      setInitCurrencyId(user.currency);
    } else {
      setInitCurrencyId(currencyData[0].id);
    }
  }, [user]);

  useEffect(() => {
    if (initCurrencyId) {
      const currency = _.find(
        currencyData,
        (data) => data.id === initCurrencyId
      );
      if (currency) {
        setSelectedCurrency(currency);
      }
    } else {
      setSelectedCurrency(currencyData[0]);
    }
  }, [initCurrencyId]);

  const changeCurrency = useCallback(
    async (currency) => {
      if (isAuthenticated) {
        await updateUser({currency: currency.id});
      }
      setSelectedCurrency(currency);
    },
    [isAuthenticated, updateUser]
  );

  const items = currencyData.map((currency, index) => {
    return {
      key: index,
      label: (
        <StyledCurrencyItem
          key={index}
          onClick={() => changeCurrency(currency)}
        >
          {currency.icon}
          <h4>{currency.name}</h4>
        </StyledCurrencyItem>
      ),
    };
  });

  return (
    <Dropdown
      menu={{items}}
      trigger={["click"]}
      overlayStyle={{zIndex: 1052}}
    >
      <Tooltip title={messages["home.changeCurrency"]} placement={"left"}>
        <StyledCurrencyBtn
          className={"ant-dropdown-link langBtn"}
          onClick={(e) => e.preventDefault()}
        >
          <StyledCurrencyIcon>{selectedCurrency.icon}</StyledCurrencyIcon>
          <StyledCurrencyText>{selectedCurrency.name}</StyledCurrencyText>
        </StyledCurrencyBtn>
      </Tooltip>
    </Dropdown>
  );
}
