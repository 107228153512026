import { authRole } from "../../shared/constants/AppEnums";

export const getUserFromJwtAuth = (user) => {
  if (user)
    return {
      id: user.username,
      username: user.username,
      uid: user.username,
      steamId: user.steamId,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.avatar,
      role: user.role === "SUPER_ADMIN" ? authRole.admin : authRole.user,
      balance: user.balance ? user.balance : 0,
      balanceVnd: user.balanceVnd ? user.balanceVnd : 0,
      currency: user.currency,
      usdRewardPoints: user.usdRewardPoints,
      vndRewardPoints: user.vndRewardPoints,
    };
  return user;
};
