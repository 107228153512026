import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import UserInfo from '../components/UserInfo';
import clsx from 'clsx';
import AppVerticalMenu from '../components/AppVerticalNav';
import {useLocation} from 'react-router-dom';
import {LayoutDirection} from '../../../../shared/constants/AppEnums';
import {useSidebarContext} from '../../../utility/AppContextProvider/SidebarContextProvider';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {
  StyledAppDrawer,
  StyledAppDrawerLayoutSidebar,
  StyledAppDrawerSidebarScrollbar,
} from './index.styled';

const AppSidebar = ({visible, onClose}) => {
  const {isSidebarBgImage} = useSidebarContext();
  const {direction} = useLayoutContext();
  const {pathname} = useLocation();

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <StyledAppDrawer
      placement={direction === LayoutDirection.LTR ? 'left' : 'right'}
      closable={false}
      onClose={onClose}
      open={visible}>
      <StyledAppDrawerLayoutSidebar
        className={clsx({
          'drawerLayout-sidebar-img-background': isSidebarBgImage,
        })}
        collapsible>
        <UserInfo hasColor />
        <StyledAppDrawerSidebarScrollbar scrollToTop={false}>
          <AppVerticalMenu />
        </StyledAppDrawerSidebarScrollbar>
      </StyledAppDrawerLayoutSidebar>
    </StyledAppDrawer>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
