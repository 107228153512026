import React from "react";
import { useAuthMethod } from "../utils/AuthHooks";
import { Button } from "antd";
import { FaSteam } from "react-icons/fa";
import IntlMessages from "../../@crema/utility/IntlMessages";
import styled from "styled-components";

const StyledSignInButton = styled(Button)`
  margin-left: 2px;
  margin-right: 2px;

  svg {
    margin-right: 4px;
    font-size: 18px;
  }
`;

export default function LoginSteamButton() {
  const { signInSteam } = useAuthMethod();

  return (
    <StyledSignInButton
      type="primary"
      size={"large"}
      icon={<FaSteam />}
      onClick={() => signInSteam()}
    >
      <IntlMessages id="auth.login.steam" />
    </StyledSignInButton>
  );
}
