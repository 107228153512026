import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useSidebarContext } from "../../@crema/utility/AppContextProvider/SidebarContextProvider";
import styled from "styled-components";
import { adminRoutesConfig, userRoutesConfig } from "../modules/routeConfig";
import { useAuthUser } from "./AuthHooks";
import { authRole } from "../../shared/constants/AppEnums";
import { darken, lighten } from "polished";

function getStyles(item, sidebarColorSet, index) {
  const { pathname } = useLocation();
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/");

  const isOpen = defaultOpenKeys[index] === item.id;
  return {
    color: isOpen
      ? sidebarColorSet.sidebarMenuSelectedTextColor
      : sidebarColorSet.sidebarTextColor,
    backgroundColor: isOpen
      ? sidebarColorSet.sidebarMenuSelectedBgColor
      : sidebarColorSet.sidebarBgColor,
  };
}

function getSelectedClass(item, sidebarColorSet, index) {
  const { pathname } = useLocation();
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/");
  return defaultOpenKeys[index] === item.id ? "selected" : "";
}

const renderMenuItemChildren = (item) => {
  const { icon, messageId, path } = item;
  const { messages } = useIntl();

  if (path && path.includes("/"))
    return (
      <Link to={path}>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className="ant-menu-item-icon">{icon}</span>
          ) : (
            <icon className="ant-menu-item-icon" />
          ))}
        <span data-testid={messageId.toLowerCase + "-nav"}>
          {messages[messageId]}
        </span>
      </Link>
    );
  else {
    return (
      <>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className="ant-menu-item-icon">{icon}</span>
          ) : (
            <icon className="ant-menu-item-icon" />
          ))}
        <span data-testid={messageId.toLowerCase + "-nav"}>
          {messages[messageId]}
        </span>
      </>
    );
  }
};

const renderMenuItem = (item, sidebarColorSet, index) => {
  return item.type === "collapse" ? (
    <Menu.SubMenu
      style={getStyles(item, sidebarColorSet, index, true)}
      key={item.path ? item.path : item.id}
      title={renderMenuItemChildren(item, sidebarColorSet)}
    >
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, index + 1)
      )}
    </Menu.SubMenu>
  ) : (
    <Menu.Item key={item.id} style={getStyles(item, sidebarColorSet, index)}>
      {item.children
        ? item.children
        : renderMenuItemChildren(item, sidebarColorSet)}
    </Menu.Item>
  );
};

const StyledSubMenu = styled(Menu.SubMenu)`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ theme }) => darken(0.5, theme.palette.background.paper)};

  & a, span {
    color: ${({ theme }) => darken(0.5, theme.palette.background.paper)};
  }

  &.selected span {
    color: ${({ theme }) => lighten(0.5, theme.palette.background.paper)};!important;
    background-color: transparent !important;
  }

  :hover span {
    color: ${({ theme }) => lighten(0.5, theme.palette.background.paper)};!important;
  }
`;

const renderHorMenu = (item, sidebarColorSet, index) => {
  return item.type === "group" ? (
    <StyledSubMenu
      style={getStyles(item, sidebarColorSet, index, true)}
      key={item.path ? item.path : item.id}
      title={renderMenuItemChildren(item, sidebarColorSet)}
      className={getSelectedClass(item, sidebarColorSet, index)}
    >
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, index + 1)
      )}
    </StyledSubMenu>
  ) : (
    <Menu.Item
      style={getStyles(item, sidebarColorSet, index, true)}
      key={item.id}
      exact={item.exact}
      className={getSelectedClass(item, sidebarColorSet, index)}
    >
      {item.children
        ? item.children
        : renderMenuItemChildren(item, sidebarColorSet)}
    </Menu.Item>
  );
};

export const getRouteHorMenus = () => {
  const { sidebarColorSet } = useSidebarContext();
  const { user } = useAuthUser();

  let routes =
    user?.role === authRole.admin ? adminRoutesConfig : userRoutesConfig;
  return routes.map((route) => renderHorMenu(route, sidebarColorSet, 0));
};
