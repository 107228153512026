// type UserRole = "SUPER_ADMIN" | "REGULAR_USER" | "ANONYMOUS";

// public class UserDto {
//   private String username;
//   private String steamId;
//   private BigDecimal balance;
//   private boolean active;
//   private UserRole role;
//   private String displayName;
//   private String avatar;
//}

import { request } from "../utils/http";

export function getUserInfo() {
  return request({
    url: "/user",
    method: "GET",
    authRequired: true,
  });
}

export function updateUserInfo({ currency, displayName, email }) {
  return request({
    url: "/user",
    method: "POST",
    authRequired: true,
    data: {
      currency: currency,
      displayName: displayName,
      email: email,
    },
  });
}

// private String address;
// private CryptoNetwork network;
export function getUserDepositTronWallet() {
  return request({
    url: "/user/tron-wallet",
    method: "GET",
  });
}

// private String username;
// private LocalDateTime createdAt;
// private Long remainingSecond;
// private boolean hasRequest;
export function createTronDepositRequest() {
  return request({
    url: "/user/create-tron-deposit-request",
    method: "POST",
  });
}

export function getTronDepositRequest() {
  return request({
    url: "/user/get-tron-deposit-request",
    method: "GET",
  });
}

export function cancelTronDepositRequest() {
  return request({
    url: "/user/cancel-tron-deposit-request",
    method: "POST",
  });
}

// private BigDecimal amount;
// private String adminPayId;
// private String orderId;
export function depositUsdtBinance(amount) {
  return request({
    url: "/user/deposit-usdt-binance",
    method: "POST",
    data: {
      amount: amount,
    },
  });
}

export function getDepositUsdtBinance(orderId) {
  return request({
    url: "/user/deposit-usdt-binance",
    method: "GET",
    params: {
      orderId: orderId,
    },
  });
}

export function cancelDepositBinance(orderId) {
  return request({
    url: "/user/cancel-binance-deposit",
    method: "POST",
    data: {
      orderId: orderId,
    },
  });
}

export function userRoll(rollItemIds, currency) {
  return request({
    url: "/user/roll",
    method: "POST",
    data: {
      rollItemIds: rollItemIds,
      currency: currency,
    },
  });
}

export function getDepositHistory(pageOffset) {
  return request({
    url: "/user/deposit-history",
    method: "GET",
    params: {
      pageOffset: pageOffset,
    },
  });
}

export function getRollHistory(pageOffset) {
  return request({
    url: "/user/roll-history",
    method: "GET",
    params: {
      pageOffset: pageOffset,
    },
  });
}

export function getTipHistory(pageOffset) {
  return request({
    url: "/user/tip-history",
    method: "GET",
    params: {
      pageOffset: pageOffset,
    },
  });
}

export function tip(steamId, tipAmount, currency) {
  return request({
    url: "/user/tip",
    method: "POST",
    data: {
      steamId: steamId,
      tipAmount: tipAmount,
      currency: currency,
    },
  });
}

export function createFastTip({steamId, tipAmount, currency, bankCode}) {
  return request({
    url: "/user/create-fast-tip-request",
    method: "POST",
    data: {
      steamId: steamId,
      tipAmount: tipAmount,
      currency: currency,
      bankCode: bankCode
    }
  });
}

export function getCsSteamInventory() {
  return request({
    url: "/user/steam-inventory-cs",
    method: "GET",
  });
}

export function getSteamUserInfo() {
  return request({
    url: "/user/steam-user-info",
    method: "GET",
  });
}

export function offerSteamItems({
  itemInfoList,
  currency,
  totalValue,
  paymentMethod,
  paymentInformation,
  bankAccount,
}) {
  return request({
    url: "/user/offer-steam-items",
    method: "POST",
    data: {
      itemInfoList,
      currency,
      totalValue,
      paymentMethod,
      paymentInformation,
      bankAccount,
    },
  });
}

export function cancelOfferSteam({ steamOfferId }) {
  return request({
    url: "/user/cancel-steam-offer",
    method: "POST",
    data: {
      id: steamOfferId,
    },
  });
}

export function notifyOfferSteam({ steamOfferId }) {
  return request({
    url: "/user/notify-steam-offer",
    method: "POST",
    data: {
      id: steamOfferId,
    },
  });
}

export function createDepBankRequest({ amount, bankCode }) {
  return request({
    url: "/user/create-dep-bank",
    method: "POST",
    data: {
      amount: amount,
      bankCode: bankCode,
    },
  });
}

export function getDepBankRequest({ orderId }) {
  return request({
    url: "/user/get-dep-bank-result",
    method: "GET",
    params: {
      orderId: orderId,
    },
  });
}

export function getRewardInfo() {
  return request({
    url: "/user/reward-info",
  });
}

export function claimReward(currency) {
  return request({
    url: "/user/claim-reward",
    method: "POST",
    data: {
      currency: currency,
    },
  });
}
