import React from "react";
import {TbCurrencyDollar, TbCurrencyDong} from "react-icons/tb";

const currencyData = [
    {
        id: "VND",
        name: "VND",
        icon: <TbCurrencyDong/>,
    },
    {
        id: "USD",
        name: "USD",
        icon: <TbCurrencyDollar/>,
    },
];

export default currencyData;
