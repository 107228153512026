import React from "react";
import { RoutePermittedRole } from "../../../shared/constants/AppEnums";
import { Navigate } from "react-router-dom";

const Dashboard = React.lazy(() => import("./Dashboard"));
const ThirdPartyConfiguration = React.lazy(() =>
  import("./Configuration/ThirdPartyConfiguration")
);
const SecurityConfiguration = React.lazy(() => import('./Configuration/SecurityConfiguration'))
const UserManagement = React.lazy(() => import("./Management/UserManagement"));
const RollManagement = React.lazy(() => import("./Management/RollManagement"));
const TipManagement = React.lazy(() => import("./Management/TipManagement"));
const DepositManagement = React.lazy(() =>
  import("./Management/DepositManagement")
);
const DepBankManagement = React.lazy(() =>
  import("./Management/DepBankManagement")
);
const WalletManagement = React.lazy(() => import("./Management/WalletManagement"))
const SteamValuationAdmin = React.lazy(() => import("../steam/SteamValuation/SteamValuationAdmin"));
const SteamOfferManagement = React.lazy(() => import("./Management/SteamOfferManagement"));

export const adminConfig = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin",
    element: <Navigate to={"/admin/dashboard"} />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/configuration/security",
    element: <SecurityConfiguration />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/configuration/third-party",
    element: <ThirdPartyConfiguration />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/dashboard",
    element: <Dashboard />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/user",
    element: <UserManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/roll",
    element: <RollManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/tip",
    element: <TipManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/transaction",
    element: <DepositManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/deposit-bank",
    element: <DepBankManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/wallet",
    element: <WalletManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/management/steamOffer",
    element: <SteamOfferManagement />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: "/admin/service/steam-valuation",
    element: <SteamValuationAdmin />,
  },
];
