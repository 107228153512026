import React from "react";
import AppErrorBoundary from "../../../../@crema/core/AppErrorBoundary";
import generateRoutes from "../../../../@crema/utility/RouteGenerator";
import { useAuthUser } from "../../../utils/AuthHooks";
import { StyledMainContentView } from "./index.styled";
import { AppSuspense } from "../../../../@crema";
import {
  adminAuthorizedStructure,
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from "../../../modules";

const AppContentView = () => {
  const { user, isAuthenticated } = useAuthUser();
  return (
    <StyledMainContentView>
      <AppSuspense>
        <AppErrorBoundary>
          {generateRoutes({
            isAuthenticated: isAuthenticated,
            userRole: user?.role,
            unAuthorizedStructure,
            authorizedStructure,
            adminAuthorizedStructure,
            anonymousStructure,
          })}
        </AppErrorBoundary>
      </AppSuspense>
    </StyledMainContentView>
  );
};

export default AppContentView;
