import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { LayoutDirection } from "../../../../shared/constants/AppEnums";
import AppVerticalMenu from "../../../../@crema/core/AppLayout/components/AppVerticalNav";

import {
  StyledAppHorDarkDrawer,
  StyledAppMainHorDarkSidebar,
  StyledAppScrollbar,
} from "./index.styled";
import { useSidebarContext } from "../../../../@crema/utility/AppContextProvider/SidebarContextProvider";
import { useLayoutContext } from "../../../../@crema/utility/AppContextProvider/LayoutContextProvider";
import { useAuthUser } from "../../../utils/AuthHooks";
import LoginSteamButton from "../../LoginSteamButton";
import UserInfo from "../../UserInfo";

const AppSidebar = ({ visible, onClose }) => {
  const { isSidebarBgImage } = useSidebarContext();
  const { direction } = useLayoutContext();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthUser();

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <StyledAppHorDarkDrawer
      placement={direction === LayoutDirection.LTR ? "left" : "right"}
      closable={false}
      onClose={onClose}
      open={visible}
    >
      <StyledAppMainHorDarkSidebar
        className={clsx({
          "hor-dark-sidebar-img-background": isSidebarBgImage,
        })}
        collapsible
      >
        {isAuthenticated ? <UserInfo /> : <LoginSteamButton />}
        <StyledAppScrollbar scrollToTop={false}>
          <AppVerticalMenu />
        </StyledAppScrollbar>
      </StyledAppMainHorDarkSidebar>
    </StyledAppHorDarkDrawer>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
