import React, { useEffect } from "react";
import { useUrlSearchParams } from "use-url-search-params";
import { LayoutType } from "../../../shared/constants/AppEnums";
import {
  useLayoutActionsContext,
  useLayoutContext,
} from "../../../@crema/utility/AppContextProvider/LayoutContextProvider";
import { useSidebarActionsContext } from "../../../@crema/utility/AppContextProvider/SidebarContextProvider";
import LayoutMap from "./LayoutMap";
import { AppInfoView } from "../../../@crema";

const AppLayout = () => {
  const { navStyle, layoutType } = useLayoutContext();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const [params] = useUrlSearchParams();

  const AppLayout = LayoutMap[navStyle];

  useEffect(() => {
    if (layoutType === LayoutType.BOXED) {
      document.body.classList.add("boxedLayout");
      document.body.classList.remove("framedLayout");
    } else if (layoutType === LayoutType.FRAMED) {
      document.body.classList.remove("boxedLayout");
      document.body.classList.add("framedLayout");
    } else {
      document.body.classList.remove("boxedLayout");
      document.body.classList.remove("framedLayout");
    }
  }, [layoutType]);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, []);

  return (
    <React.Fragment>
      <AppLayout />
      <AppInfoView />
    </React.Fragment>
  );
};

export default React.memo(AppLayout);
