import jwtDecode from 'jwt-decode';
import { request } from "../utils/http";

const AUTH_TOKEN = 'AUTH_TOKEN';

// export interface ILoginInfo {
//   username: string;
//   password: string;
//   rememberMe?: boolean;
// }
//
// export interface ITokenInfo {
//   accessToken: string;
//   expiresIn: number;
// }

export function loginSteam() {
  return request({
    url: '/auth/login-steam',
    method: 'POST',
    authRequired: false,
  });
}

export function login({username, password}) {
  return request({
    url: '/auth/login',
    method: 'POST',
    authRequired: false,
    data: {
      username,
      password,
    },
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'POST',
    authRequired: true,
  });
}

export function verifySteamUrl(steamUrl) {
  return request({
    url: '/auth/verify-steam',
    method: 'POST',
    data: {verifyUrl: steamUrl},
    authRequired: false,
  });
}

export function getAuthInfoFromCache() {
  const token = getAuthToken();
  return getAuthInfo(token);
}

function getAuthInfo(token) {
  let jwt;
  try {
    jwt = jwtDecode(token);
  } catch (e) {
    jwt = {};
  }

  return jwt.sub;
}

export function getAuthToken() {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(AUTH_TOKEN + '=');
    if (start !== -1) {
      start = start + AUTH_TOKEN.length + 1;
      let end = document.cookie.indexOf(';', start);
      if (end === -1) {
        end = document.cookie.length;
      }
      return unescape(document.cookie.substring(start, end));
    }
  }
  return '';
}
