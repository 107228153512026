import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Tooltip } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useAuthMethod, useAuthUser } from "../../utils/AuthHooks";
import { lighten } from "polished";
import { useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import AnimateNumber from "../AnimateNumber";
import { useIntl } from "react-intl";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  background-color: ${({ theme }) =>
    lighten(0.03, theme.sidebar.dark.sidebarBgColor)};
`;

const StyledBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 12px;
  padding-left: 12px;
`;

const StyledMainBalance = styled.div`
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`;

const StyledSubBalance = styled.div`
  font-size: 0.8rem;
  color: gray;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export default function UserBalance() {
  const { user, isAuthenticated } = useAuthUser();
  const { refreshUser } = useAuthMethod();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const [loadingBalance, setLoadingBalance] = useState(false);

  const mainBalance = useMemo(() => {
    if (user.currency === "VND") {
      return user.balanceVnd;
    }
    return user.balance;
  }, [user.balance, user.balanceVnd, user.currency]);

  const subBalance = useMemo(() => {
    if (user.currency === "VND") {
      return user.balance;
    }
    return user.balanceVnd;
  }, [user.balance, user.balanceVnd, user.currency]);

  const handleClickDeposit = useCallback(() => {
    navigate("/user/deposit");
  }, []);

  const onRefreshUserBalance = useCallback(async () => {
    setLoadingBalance(true);
    await refreshUser();
    setLoadingBalance(false);
  }, [refreshUser]);

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <StyledContainer>
      <StyledBalanceContainer>
        <StyledMainBalance>
          {user.currency === "USD" && <span>$</span>}
          <AnimateNumber value={mainBalance} formatMoney={true} />
          {user.currency === "VND" && <span>đ</span>}
        </StyledMainBalance>
        {subBalance > 0 && (
          <StyledSubBalance>
            {user.currency !== "USD" && <span>$</span>}
            <AnimateNumber value={subBalance} formatMoney={true} />
            {user.currency !== "VND" && <span>đ</span>}
          </StyledSubBalance>
        )}
      </StyledBalanceContainer>
      <StyledButtonContainer>
        <Button
          loading={loadingBalance}
          style={{ color: "white" }}
          icon={<SyncOutlined />}
          onClick={onRefreshUserBalance}
          type={"text"}
        />
        <Tooltip title={messages["common.deposit"]}>
          <Button icon={<AiOutlinePlus />} onClick={handleClickDeposit} />
        </Tooltip>
      </StyledButtonContainer>
    </StyledContainer>
  );
}
