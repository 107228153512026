import React from "react";
import PropTypes from "prop-types";
import { FiMoreVertical } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import {
  StyledAppHeader,
  StyledAppHeaderSectionDesk,
  StyledAppHeaderSectionMobile,
} from "./index.styled";
import { Dropdown } from "antd";
import { StyledDropdownWrapper } from "../index.styled";
import AppLanguageSwitcher from "../../../../@crema/core/AppLanguageSwitcher";
import AppLogo from "../../AppLogo";
import AppCurrencySwitcher from "../../AppCurrencySwitcher";

const items = [
  { key: 1, label: <AppLanguageSwitcher /> },
  { key: 2, label: <AppCurrencySwitcher /> },
];

const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  return (
    <StyledAppHeader>
      <a className="trigger" onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      <div style={{width: "100%"}}/>
      <StyledAppHeaderSectionDesk>
        <AppLanguageSwitcher />
        <AppCurrencySwitcher />
      </StyledAppHeaderSectionDesk>
      <StyledAppHeaderSectionMobile>
        <StyledDropdownWrapper>
          <Dropdown
            menu={{ items }}
            overlayClassName="dropdown-wrapper"
            getPopupContainer={(triggerNode) => triggerNode}
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link-mobile"
              onClick={(e) => e.preventDefault()}
            >
              <FiMoreVertical />
            </a>
          </Dropdown>
        </StyledDropdownWrapper>
      </StyledAppHeaderSectionMobile>
    </StyledAppHeader>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
