import vnMessages from "../locales/vi_VN.json";
import viVN from "antd/lib/locale/vi_VN";

const VnLang = {
  messages: {
    ...vnMessages,
  },
  antLocale: viVN,
  locale: "vi-VN",
};

export default VnLang;
